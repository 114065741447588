import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { BellOutlineM } from '@alphakits/icons';
import {
    Amount,
    Button,
    ModalContext,
    Status,
    Typography,
    useMatchMedia,
} from '@alphakits/ui/dist';
import { CrmVerificationEnum } from '@escapenavigator/types/dist/profile/enum/crm-verifiaction.enum';
import { ProfileSubscriptionTypeEnum } from '@escapenavigator/types/dist/profile/enum/profile-subscription-type.enum';
import { ProfileSubscriptionEnum } from '@escapenavigator/types/dist/profile/enum/profile-subscription.enum';
import { ChangeSubscriptionPlanModal } from 'src/modals/change-subscription-plan';
import { NotificationModal } from 'src/modals/notification';
import { useCurrentUser } from 'src/providers/current-user/context';
import { useOnboarding } from 'src/providers/ongoarding/context';
import { ROUTE_SUBSCRIPTION } from 'src/router/constants';
import { selectAllQuestrooms } from 'src/store';
import { isRu } from 'src/utils/is-ru';

import styles from './index.module.css';

export const NotificationButton = () => {
    const { openModal } = useContext(ModalContext);
    const {
        profile: {
            balance, crmVerification, subscriptionStatus, subscriptionType, currency,
        },
    } = useCurrentUser();
    const questrooms = useSelector(selectAllQuestrooms({ closed: false, deleted: false }));

    const {
        steps, loading, showBellAlert, removeBellAlert,
    } = useOnboarding();

    const openNotification = () => {
        removeBellAlert();
        openModal(NotificationModal)();
    };
    const [isDesktop] = useMatchMedia('(min-width: 980px)');

    const balanceButtonView = useMemo(() => {
        if (balance > questrooms.length * 120) return 'brandBg';
        if (balance > questrooms.length * 10) return 'warningBg';

        return 'errorBg';
    }, [balance, questrooms.length]);

    const progress = useMemo(() => {
        if (!steps) return 0;
        const all = Object.values(steps);
        const done = all.filter((value) => !!value).length;

        return Math.ceil((done * 100) / all.length);
    }, [steps]);

    const setupComplite = crmVerification === CrmVerificationEnum.SUCCEED || !isDesktop;
    const ru = isRu();
    const showAwaitingPayment =
        !ru &&
        subscriptionType === ProfileSubscriptionTypeEnum.PRO &&
        (subscriptionStatus === ProfileSubscriptionEnum.INCOMPLITE ||
            subscriptionStatus === ProfileSubscriptionEnum.PAST_DUE ||
            subscriptionStatus === ProfileSubscriptionEnum.UNPAID);

    return (
        <React.Fragment>
            { ru && (
                <Button
                    className={ balanceButtonView }
                    size="xs"
                    onClick={ () => {
                        window.location.href =
                            process.env.REACT_APP_APP_DOMAIN + ROUTE_SUBSCRIPTION;
                    } }
                >
                    <Amount type="decimal" weight="bold" value={ balance } currency={ currency } />
                </Button>
            ) }

            { setupComplite && isDesktop && subscriptionType === ProfileSubscriptionTypeEnum.FREE && (
                <Button
                    className="brandBg"
                    onClick={ () => openModal(ChangeSubscriptionPlanModal, 'm')({ type: 'change' }) }
                >
                    Upgrade
                </Button>
            ) }

            { setupComplite && isDesktop && showAwaitingPayment && (
                <Button
                    view="ghost"
                    onClick={ () => {
                        window.location.href =
                            process.env.REACT_APP_APP_DOMAIN + ROUTE_SUBSCRIPTION;
                    } }
                >
                    <Status color="orange" view="contrast" size="m">
                        Awaiting payment
                    </Status>
                </Button>
            ) }
            <div className={ showBellAlert && styles.active }>
                <Button
                    onClick={ () => openNotification() }
                    className={ styles.button }
                    view="secondary"
                    loading={ loading }
                    size="xs"
                    leftAddons={ <BellOutlineM /> }
                >
                    { !setupComplite && (
                        <React.Fragment>
                            <Typography.Text view="title" weight="regular" color="primary">
                                Onboarding ({ progress }%)
                            </Typography.Text>

                            <div className={ styles.progressWrap }>
                                <div
                                    className={ styles.progress }
                                    style={ { width: `${progress}%` } }
                                />
                            </div>
                        </React.Fragment>
                    ) }
                </Button>
            </div>
        </React.Fragment>
    );
};

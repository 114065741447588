import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    EmptyPage,
    Flex,
    FlexColumns,
    ModalContext,
    Padding,
    Typography,
} from '@alphakits/ui';
import { ProfileSubscriptionTypeEnum } from '@escapenavigator/types/dist/profile/enum/profile-subscription-type.enum';
import { BackButton } from 'src/components/back-button';
import { ScheduleCalendar } from 'src/components/layout/core/calendar';
import { ChangeSubscriptionPlanModal } from 'src/modals/change-subscription-plan';
import { SupportModal } from 'src/modals/support';
import { useCurrentUser } from 'src/providers/current-user/context';
import { notFoundRoute } from 'src/router/routes-tree';
import { Route } from 'src/router/types';
import { isRu } from 'src/utils/is-ru';

import { AlphaLogo } from '../logo/alpha-logo';
import { PageTitle } from '../page-title';

import { CompanySwitcher } from './company-switcher';
import { MenuButtonsList } from './menu-buttons-list';
import { NotificationButton } from './notification-button';
import { SearchButton } from './search-button';
import { UserButton } from './user-button';

import styles from './index.module.css';

type Props = {
    routes: Record<string, Route>;
};

export const Layout: React.FC<Props> = ({ children, routes }) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const { current, profile, pro } = useCurrentUser();
    const { openModal } = useContext(ModalContext);

    const { t } = useTranslation();

    // const { setTheme, currentTheme } = useTheme();

    const currentRoute = useMemo(() => {
        const arr = Object.entries(routes).reduce((acc, [path, route]) => {
            if (route.type === 'path') {
                acc.push([path, route]);
            } else {
                Object.entries(route.children).forEach(([nestedPath, nestedRoute]) => {
                    acc.push([nestedPath, nestedRoute]);
                });
            }

            return acc;
        }, [] as Array<[string, Route]>);

        return Object.fromEntries(arr)[pathname] || notFoundRoute['*'];
    }, [routes, pathname]);

    if (currentRoute.pure) return <React.Fragment>{ children }</React.Fragment>;

    const onlyPro = currentRoute.onlyPro && !pro;

    return (
        <div className={ styles.container }>
            <div className={ styles.menu }>
                <Link to="/" className={ styles.logo }>
                    <AlphaLogo />
                    Escape Navigator
                </Link>

                <div className={ styles.menuCalendar }>
                    <ScheduleCalendar />
                </div>

                { !!current.companies?.length && (
                    <div className={ styles.companies }>
                        <CompanySwitcher />
                    </div>
                ) }

                <div className={ styles.menuItems }>
                    <MenuButtonsList routes={ routes } />

                    <Padding padding="16px 8px 0">
                        <Flex direction="column" gap="sm">
                            { !isRu() &&
                                profile.subscriptionType === ProfileSubscriptionTypeEnum.NONE && (
                                <Box padding="md" background="#EDEFF0" rounded="sm">
                                    <FlexColumns columns={ 1 } gr={ 12 }>
                                        <Typography.Text view="caps">
                                            { t('Подключите CRM-систему') }
                                        </Typography.Text>

                                        <Button
                                            className="brandBg"
                                            onClick={ () =>
                                                openModal(
                                                    ChangeSubscriptionPlanModal,
                                                    'm',
                                                )({ type: 'new' }) }
                                        >
                                            { t('Подключить CRM') }
                                        </Button>
                                    </FlexColumns>
                                </Box>
                            ) }

                            <Button view="ghost" onClick={ () => openModal(SupportModal)() }>
                                <Typography.Text view="caps" color="secondary">
                                    { t('options.support') }
                                </Typography.Text>
                            </Button>
                        </Flex>
                    </Padding>
                </div>
            </div>

            <div className={ styles.content }>
                <div className={ styles.header }>
                    <SearchButton />

                    <Flex gap="md">
                        { profile.subscriptionType !== ProfileSubscriptionTypeEnum.NONE && (
                            <NotificationButton />
                        ) }
                        <UserButton />
                    </Flex>
                </div>

                { currentRoute.type === 'subpage' && (
                    <Padding padding="8px 0 0 0">
                        <BackButton onClick={ () => navigate(-1) } />
                    </Padding>
                ) }

                { !onlyPro && !currentRoute.hidePagetitle && (
                    <PageTitle title={ currentRoute.title } />
                ) }

                <div className={ styles.body } id="content">
                    { onlyPro ? (
                        <EmptyPage
                            title="Available in Pro version"
                            image="https://landing.escapenavigator.ru/static/images/agregator.webp"
                            buttonText="Get PRO"
                        />
                    ) : (
                        children
                    ) }
                </div>
            </div>
        </div>
    );
};

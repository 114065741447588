import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';
import { EmptyPage, ModalProvider, useMatchMedia } from '@alphakits/ui/dist';
import { ProfileStatusEnum } from '@escapenavigator/types/dist/profile/enum/profile-step.enum';
import { ProfileSubscriptionTypeEnum } from '@escapenavigator/types/dist/profile/enum/profile-subscription-type.enum';
import { parse, stringify } from 'query-string';
import { useCurrentUser } from 'src/providers/current-user/context';
import {
    fetchCashboxes,
    fetchCertificates,
    fetchLocations,
    fetchOptions,
    fetchQuestrooms,
    fetchRoles,
    fetchTariffs,
    fetchUsers,
} from 'src/store';
import { useAppDispatch } from 'src/store/hooks';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import { Layout } from './components/layout/core';
import { LayoutLoader } from './components/layout/layout-loader';
import { useScrollToAnchor } from './hooks/use-scroll-to-anchor';
import { CalendarMobilePage } from './pages/calendar-mobile';
import { VerifyCompany } from './pages/verify-company';
import { VerifyUser } from './pages/verify-user';
import { ApiProvider } from './providers/api/context';
import { CurrentUserProvider } from './providers/current-user/context';
import { OnboardingProvider } from './providers/ongoarding/context';
import { useAppRoutes } from './router/use-app-routes';
import { isRu } from './utils/is-ru';

const Root: React.FC = () => {
    const { t, i18n } = useTranslation();

    useScrollToAnchor();

    const { profile, current } = useCurrentUser();

    const [isDesktop] = useMatchMedia('(min-width: 980px)');

    const { router, routes } = useAppRoutes({
        t,
        profile,
        currentUser: current,
        isDesktop,
    });

    const [loading, setLoading] = useState(true);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (current.user.profileId) {
            Promise.all([
                dispatch(fetchCertificates()),
                dispatch(fetchRoles()),
                dispatch(fetchQuestrooms()),
                dispatch(fetchCashboxes()),
                dispatch(fetchOptions()),
                dispatch(fetchLocations()),
                dispatch(fetchUsers()),
                dispatch(fetchTariffs()),
            ]).then(() => {
                const defaultLang = current.user.language || navigator.language.slice(0, 2);
                const language = isRu() ? 'ru' : defaultLang;

                i18n.changeLanguage(language);

                setLoading(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, i18n]);

    if (loading) return <LayoutLoader />;

    // если пользователь заходит впервые и не задал пароль
    if (!current.user.name && current.mode !== 'admin') {
        return <VerifyUser />;
    }

    // если пользователь ранее отменял участие на агрегаторе
    if (profile.status === ProfileStatusEnum.CANCELED && current.mode !== 'admin') {
        return <React.Fragment>You reject service</React.Fragment>;
    }

    // если пользователь еще не подтвердил данные
    if (profile.status !== ProfileStatusEnum.VERIFIED && current.mode !== 'admin') {
        return <VerifyCompany />;
    }

    // если пользователь не пользуется CRM и заходит с мобилы
    if (profile.subscriptionType === ProfileSubscriptionTypeEnum.NONE && !isDesktop) {
        return (
            <EmptyPage
                title={ t('completeVerification.title') }
                subtitle={ `${t('completeVerification.mobile')}` }
                image="https://escapenavigator-images.s3.eu-central-1.amazonaws.com/inside/widget/widgetComplereOrder.webp"
            />
        );
    }

    if (!isDesktop) {
        return (
            <CalendarMobilePage
                userData={ {
                    profile,
                    profileId: current.profileId,
                    role: current.role,
                    user: current.user,
                } }
                t={ t }
            />
        );
    }

    return (
        <Layout routes={ routes }>
            { /* <HeaderAlerts /> */ }
            { router }
        </Layout>
    );
};

function App() {
    const { t } = useTranslation();

    return (
        <ApiProvider>
            <CurrentUserProvider>
                <OnboardingProvider>
                    <Router>
                        <QueryParamProvider
                            adapter={ ReactRouter6Adapter }
                            options={ {
                                searchStringToObject: parse,
                                objectToSearchString: stringify,
                            } }
                        >
                            <ModalProvider t={ t }>
                                <Root />
                            </ModalProvider>
                        </QueryParamProvider>
                    </Router>
                </OnboardingProvider>
            </CurrentUserProvider>
        </ApiProvider>
    );
}

export default App;
